const MENU_BTN = document.querySelector('.header__btn')
const LOGO = document.querySelector('.header__logo')
const MENU = document.querySelector('.header__menu')

const MODAL_BG = document.querySelector('.modal-bg')
const MODAL_THANKS = document.querySelector('.modal-thanks')
const MODAL_ERROR = document.querySelector('.modal-error')
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')
const MODAL_TITLE = document.querySelector('.modal-title')
const MODAl_DESC = document.querySelector('.modal-desc')
const MODAL_SUM = document.querySelector('.modal-sum')

const QUESTIONS_TAB = document.querySelectorAll('.questions-tab')


//Открытие мобильного меню
MENU_BTN.addEventListener('click' , () => {
    MENU_BTN.classList.toggle('active')
    LOGO.classList.toggle('active')
    MENU.classList.toggle('active')
})

//Закрытие меню при изменении ширины экрана
$(window).resize(function () {
    if ($(this).width() > 768) {
        MENU_BTN.classList.remove('active')
        LOGO.classList.remove('active')
        MENU.classList.remove('active')
    }
})

//Остановка прокрутки сайта
function scrollStop() {
    document.querySelector("html").style.overflow = 'hidden'
}

//Восстановление прокрутки сайта
function scrollAuto() {
    document.querySelector("html").style.overflow = ''
}

//Функция закрытия модальных окон
function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    scrollAuto()
}
// Модальное окно СПАСИБО
function openThanksModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_THANKS.classList.add('active')
}
// Модальное окно ОШИБКА
function openErrorsModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_ERROR.classList.add('active')
}

//Закрытие модального окна по клику на крестик
closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})
//Открытие модального окна
openModal.forEach((item) => {
    item.addEventListener('click', () => {
        let title = item.getAttribute('data-title')
        let desc = item.getAttribute('data-desc')
        let sum = item.getAttribute('data-sum')
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                MODAL_TITLE.innerText = title
                MODAl_DESC.innerText = desc
                MODAL_SUM.innerHTML = sum
                scrollStop()
            }
        })
    })
})

//Инициализация слайдера

function sliderInit() {
    const teamSlider = new Swiper('.team__slider', {
        loop: true,
        speed: 400,
        slidesPerView: 3,
        centeredSlides: true,
        pagination: {
            el: '.team-pagination',
            type: 'bullets',
        },

        navigation: {
            nextEl: '.team-button-prev',
            prevEl: '.team-button-next',
        },
        breakpoints: {
            320: {
                slidesPerView: "auto",
                centeredSlides: false,
                spaceBetween: 20,
            },
            767: {
                centeredSlides: true,
                spaceBetween: 20,
            },
            1199: {
                spaceBetween: 40,
            },
        },

    })
    const casesSlider = new Swiper('.cases__slider', {
        loop: true,
        speed: 400,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
            el: '.cases-pagination',
            type: 'bullets',
        },

        navigation: {
            nextEl: '.cases-button-prev',
            prevEl: '.cases-button-next',
        },
        breakpoints: {
            320: {
                spaceBetween: 20,
            },
            960: {
                centeredSlides: true,
                spaceBetween: 40,
            },
        },

    })
}

sliderInit()


QUESTIONS_TAB.forEach((item) => {
    item.addEventListener('click', () => {
        if (item.classList.contains('active')) {
            item.classList.remove('active')
        } else {
            // QUESTIONS_TAB.forEach((item) => {
            //     item.classList.remove('active')
            // })
            item.classList.add('active')
        }
    })
})

//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }

});

const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')

function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}



//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault()
        $(this).find('input').each(function() {
            let $input = $(this);

            if ($input.attr('name') === 'name' && /\d/.test($input.val())) {
                $input.addClass('invalid');
            }
        });
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
        }).done(function () {
            let fileName = 'Прикрепить файл';
            $('form').trigger('reset');
            $('.modal-thanks').fadeIn();
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            openThanksModal()
            sendStats()
        }).fail(function () {
            openErrorsModal()
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            $('form').trigger('reset');
        })
    });
});



